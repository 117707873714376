import React, { MouseEventHandler, useEffect, useState } from "react";
import MainBG from "../../assets/backgrounds/news/news_main_bg.png";
import { Section } from "../../components/section/section.component";
import { News } from "./news.styles";
import { SocialTitle } from "../../components/social-title/social-title.component";
import { Wrap } from "../../components/wrap/wrap.component";
import { Divider } from "../../components/divider/divider.styles";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import { useMediaQuery } from "../../utils/use-media-query";
import { useNavigate } from "react-router-dom";
import { uiDataMediaCenterNews, uiDataWebsiteText } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { SiteContentMediaCenterNews } from "../../api/types/fetch.ui.types";
import shareIcon from "../../assets/icons/share_icon.svg";
import XIcon from "../../assets/icons/social/x-icon.svg"
import InstagramIcon from "../../assets/icons/social/instagram.svg";

import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { Loader } from "../../components/loader/loader.component";

import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

export const NewsPage: React.FC = (): JSX.Element => {
  const [showLinks, setShowLinks] = useState(null);
  const { mediaCenterNews } = useSelector(uiDataWebsiteText);
  const mediaCenterNewsData = useSelector(uiDataMediaCenterNews);
  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery('md');
  const [loader, setLoader] = useState(false);

  const nav = useNavigate();

  const handleClick =
    (link: string, type: number): MouseEventHandler<HTMLDivElement> =>
      () => {
        if (type) {
          window.location.replace(`/PressReleaseDocs/${link.split('/')[1]}.pdf`)
        }
        else {
          window.open(link, "_blank");
        }
      };
  useEffect(() => {
    if (!mediaCenterNewsData) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [mediaCenterNewsData]);
  return (
    <>
      <HelmetContainer metaData={mediaCenterNews.metaData} />
      <SlideSection
        bgImage={MainBG}
        title={mediaCenterNews.mc_nws_headone + " " + mediaCenterNews.mc_nws_headone_txt}
        justify={"center"}
        titleWidth={"400px"}
        titleAlign={"center"}
        m={"auto auto 11rem"}
        BgImageStyles={
          (isMobile || isTablet)
            ? {
              backgroundPosition: "50%",
              backgroundSize: "100%",
              height: "180%"
            } : {
              backgroundPosition: "50%",
              backgroundSize: "70rem",
            }
        }
      />
      <Section mainContent>
        {loader ? (
          <Loader />
        ) : (
          <>
            <News.Root>
              {mediaCenterNewsData ? (
                mediaCenterNewsData.map((item: SiteContentMediaCenterNews, newsIndex: any) => (
                  <>
                    <News.Item.Root key={item.mc_news_id}>
                      <News.Item.Preview multiImages={Array.isArray(item.mc_nws_img)}>
                        {item.mc_nws_img.length === 1 && (
                          <Wrap sx={{ gridArea: "image" }}>
                            <img src={`assets/images/pressRelease/${item.mc_nws_img[0]}`} alt={"img-1"} style={{ position: "relative", width: "100%" }} />
                          </Wrap>
                        )}
                        {Array.isArray(item.mc_nws_img) &&
                          //@ts-ignore
                          item.mc_nws_img.map((image, idx) => (
                            <Wrap sx={{ gridArea: idx === 0 ? "image" : "image-secondary" }} key={image}>
                              <img src={`assets/images/pressRelease/${image}`} alt={"img-1"} style={{ position: "relative", width: "100%" }} />
                            </Wrap>
                          ))}
                        {item.mc_nws_img.length > 1 && (
                          <Wrap style={{ display: "flex" }} sx={{ gridArea: "image" }} key={item.mc_nws_img}>
                            <Wrap style={{ cursor: "pointer" }} onClick={handleClick(item.mc_nws_link, +item.mc_nws_link_type)}>
                              <Wrap>
                                <SocialTitle
                                  date={item.mc_nws_date}
                                  linkedInLink={item.mc_nws_linkedin}
                                  title={item.mc_nws_link_txt}
                                  twitterLink={item.mc_nws_twitter}
                                />
                                <img
                                  src={`assets/images/pressRelease/${item.mc_nws_img}`}
                                  alt={"img-1"}
                                  style={{
                                    // position: "relative",
                                    width: isMobile ? "100%" : "250px",
                                    height: isMobile ? "100%" : "150px",
                                    float: isMobile ? "none" : "left",
                                    minHeight: "150px",
                                    minWidth: "250px",
                                    margin: "3px 5px 0px 0px",
                                    alignSelf: isMobile ? "center" : "flex-start"
                                  }}
                                />
                                <News.Item.Description>{item.mc_nws_paraone}</News.Item.Description>
                              </Wrap>

                              <News.Share.MobileShareLinkDiv>
                                <News.Share.ShareDivider></News.Share.ShareDivider>
                                <Wrap sx={{ display: "flex", alignItems: "center", columnGap: "20px", marginTop: "15px" }}>
                                  <img
                                    onClick={() => window.open(item.mc_nws_linkedin, "_blank")}
                                    src={InstagramIcon}
                                    alt=""
                                    width={"26px"}
                                  />
                                  <img
                                    onClick={() => window.open(item.mc_nws_twitter, "_blank")}
                                    src={XIcon}
                                    alt=""
                                    width={"25px"}
                                  />
                                </Wrap>

                              </News.Share.MobileShareLinkDiv>

                            </Wrap>
                            <News.Share.ShareDiv>
                              <News.Share.ShareTextDiv
                                onMouseEnter={() => setShowLinks(newsIndex)}
                                onMouseLeave={() => setShowLinks(null)}
                              >
                                <News.Share.ShareImg>
                                  <img src={shareIcon} alt="" width={"23px"} />
                                </News.Share.ShareImg>
                                Share this post
                                {showLinks === newsIndex && (
                                  <News.Share.ShareLinkDiv onMouseEnter={() => setShowLinks(newsIndex)}>
                                    <News.Share.ShareLinkedinDiv onClick={() => window.open(item.mc_nws_linkedin, "_blank")} >
                                      <img style={{ margin: "0px 20px" }} src={InstagramIcon} alt="" width={"26px"} />
                                      <News.Share.ShareLinksText>Share on Instagram</News.Share.ShareLinksText>
                                    </News.Share.ShareLinkedinDiv>
                                    <News.Share.ShareTwitterDiv onClick={() => window.open(item.mc_nws_twitter, "_blank")}>
                                      <img style={{ margin: "0px 20px 0px 21px" }} src={XIcon} alt="" width={"25px"} />
                                      <News.Share.ShareLinksText>Share on X</News.Share.ShareLinksText>
                                    </News.Share.ShareTwitterDiv>
                                  </News.Share.ShareLinkDiv>
                                )}
                              </News.Share.ShareTextDiv>
                            </News.Share.ShareDiv>
                          </Wrap>
                        )}
                        {item.mc_nws_img.length <= 1 && <Divider variant="dashed" />}
                      </News.Item.Preview>
                    </News.Item.Root>
                  </>
                ))
              ) : (
                <></>
              )}
            </News.Root>
          </>
        )}
      </Section>
    </>
  );
};

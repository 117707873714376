import styled from "styled-components";
import { media } from "../../utils/use-media-query";

const NewsRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0px auto;
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const NewsItemRoot = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  @media (${media.sm}) {
    margin-bottom: 3rem;
  }
`;

const NewsItemPreviewRoot = styled.div<{ multiImages?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-areas:
    "image"
    "separator"
    "description";
  grid-template-columns: 1fr;
  column-gap: 3rem;
  row-gap: 3rem;

  ${(props) =>
    props.multiImages &&
    `
    grid-template-areas:
    "image image-secondary"
    "image description";
    grid-template-columns: 1fr 1.4fr;
  `};

  @media (${media.sm}) {
    grid-template-areas:
      "image"
      "image-secondary"
      "description";
    grid-template-columns: 1fr;
  }
`;

const NewsItemDescription = styled.div`
  grid-area: description;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: justify;
`;

const ShareMainDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;
const ShareLeftDiv = styled.div`
  width: 65%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
const ShareDiv = styled.div`
  width: 250px;
  height: 125px;
  border-left: 1px solid #565656;
  margin-left: 20px;
  padding-left: 35px;
  @media (max-width: 1000px) {
    display: none;
  }
`;
const ShareTextDiv = styled.div`
  border-top: 1px solid #565656;
  border-bottom: 1px solid #565656;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  cursor: pointer;
  position: relative;
  width: 220px;
  :hover {
    background-color: rgba(60, 50, 30, 0.2);
  }
`;
const ShareImg = styled.div`
  width: 25px;
  height: 24px;
  border-radius: 5px;
  background-color: #565656;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 15px;
`;
const ShareLinkDiv = styled.div`
  position: absolute;
  right: -95%;
  top: 10%;
  width: 210px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  flex-direction: column;
  @media (max-width: 1200px) {
    right: -70px;
    width: 70px;
  }
`;
const ShareLinkedinDiv = styled.div`
  align-items: center;
  display: flex;
`;
const ShareTwitterDiv = styled.div`
  align-items: center;
  display: flex;
  margin-top: 20px;
`;
const ShareLinksText = styled.p`
  min-width: 150px;
  @media (max-width: 1200px) {
    display: none;
  }
`;
const MobileShareLinkDiv = styled.div`
  justify-content: flex-start;
  width: 100%;
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;
const ShareDivider = styled.div`
  width: 100%;
  border-top: 1px solid white;
  margin-top: 30px;
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const News = {
  Root: NewsRoot,
  Item: {
    Root: NewsItemRoot,
    Preview: NewsItemPreviewRoot,
    Description: NewsItemDescription,
  },
  Share: {
    ShareMainDiv: ShareMainDiv,
    ShareLeftDiv: ShareLeftDiv,
    ShareDiv: ShareDiv,
    ShareTextDiv: ShareTextDiv,
    ShareImg: ShareImg,
    ShareLinkDiv: ShareLinkDiv,
    ShareLinkedinDiv: ShareLinkedinDiv,
    ShareTwitterDiv: ShareTwitterDiv,
    ShareLinksText: ShareLinksText,
    MobileShareLinkDiv: MobileShareLinkDiv,
    ShareDivider: ShareDivider,
  },
};

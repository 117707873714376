import styled from "styled-components";

import { media } from "../../utils/use-media-query";
import { ArticleItem } from "./investor_news.types";

const NewsSectionRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0px auto;
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const NewsSectionTitleRoot = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 4.5rem;
`;

const NewsSectionTitle = styled.h4`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.8rem;
  letter-spacing: 0.135em;
  color: #ffffff;
  margin-right: 2rem;
  text-transform: uppercase;
  min-width: max-content;
`;

const ArticlesRoot = styled.div<{ reverse?: boolean }>`
  // display: grid;
  // grid-template-areas:
  //   "big small-top"
  //   "big small-bottom";
  // grid-template-columns: 2fr 1fr;
  // grid-template-rows: 1fr 1fr;
  // grid-column-gap: 2rem;
  // grid-row-gap: 2rem;
  min-height: 90rem;
  height: 100%;
  width: 70%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  ${(props) =>
    props.reverse &&
    `
    grid-template-areas:
      "small-top big"
      "small-bottom big";
    grid-template-columns: 1fr 75.2rem;
  `};

  @media (${media.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (${media.sm}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "small-top"
      "big"
      "small-bottom";
    grid-template-rows: 1fr 1fr 2fr;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const ArticleItemRoot = styled.div<Pick<ArticleItem, "size" | "imgSrc">>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // grid-area: ${(props) => props.size};
  background: url(${(props) => props.imgSrc}) no-repeat center;
  background-size: contain;
  background-position: cover;
  padding: 2.2rem 2.4rem;
  cursor: pointer;
  width: 250px;
  height: 150px;
  min-width: 250px;
  min-height: 150px;
  float: left;
  margin: 6px 10px 0px 0px;
`;

const ArticleDate = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 18px;
  color: #e0e0e0;
  @media (${media.sm}) {
     {
   text-align: right;
   color: #A1A1A1;
   align-self: flex-end;
   justify-content: end;
   display: flex;
   padding-bottom: 15px;
    }
  }
`;

const ArticleTitle = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #6c6c6c;
  width: "60%";
  margin-bottom: 6rem;
  @media (${media.sm}) {
     {
      margin-bottom: 2rem;
      color: #fff;
      font-size: 20px;
      line-height: 32px;
      text-transform: uppercase;
    }
  }
`;

const ArticleDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  
  color: #fff;
  @media (${media.sm}) {
     {
      color: #A1A1A1;
      
    }
  }
`;

const FormTitle = styled.h3`
  margin-bottom: 4rem;
`;
const FormSubtitle = styled.span`
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 3rem;
`;
const ShareMainDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  cursor: pointer
`;
const ShareLeftDiv = styled.div`
  width: 65%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
const ShareDiv = styled.div`
  width: 250px;
  height: 125px;
  border-left: 1px solid #565656;
  margin-left: 20px;
  padding-left: 35px;
  @media (max-width: 1000px) {
    display: none;
  }
`;
const ShareTextDiv = styled.div`
  border-top: 1px solid #565656;
  border-bottom: 1px solid #565656;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  cursor: pointer;
  position: relative;
  :hover {
    background-color: rgba(60, 50, 30, 0.2);
  }
`;
const ShareImg = styled.div`
  width: 25px;
  height: 24px;
  border-radius: 5px;
  background-color: #565656;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 15px;
`;
const ShareLinkDiv = styled.div`
  position: absolute;
  right: -98%;
  top: 10%;
  width: 210px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  flex-direction: column;
  @media (max-width: 1200px) {
    right: -37%;
    width: 70px;
  }
`;
const ShareLinkedinDiv = styled.div`
  align-items: center;
  display: flex;
`;
const ShareTwitterDiv = styled.div`
  align-items: center;
  display: flex;
  margin-top: 20px;
`;
const ShareLinksText = styled.p`
  min-width: 150px;
  @media (max-width: 1200px) {
    display: none;
  }
`;
const MobileShareLinkDiv = styled.div`
  justify-content: flex-start;
  width: 100%;
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;
const ShareDivider = styled.div`
  width: 100%;
  border-top: 1px solid white;
  margin-top: 30px;
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const News = {
  Root: NewsSectionRoot,
  Title: {
    Root: NewsSectionTitleRoot,
    Body: NewsSectionTitle,
  },
  Articles: {
    Root: ArticlesRoot,
    Item: {
      Root: ArticleItemRoot,
      Date: ArticleDate,
      Title: ArticleTitle,
      Description: ArticleDescription,
    },
  },
  Form: {
    Title: FormTitle,
    Subtitle: FormSubtitle,
  },
  Share: {
    ShareMainDiv: ShareMainDiv,
    ShareLeftDiv: ShareLeftDiv,
    ShareDiv: ShareDiv,
    ShareTextDiv: ShareTextDiv,
    ShareImg: ShareImg,
    ShareLinkDiv: ShareLinkDiv,
    ShareLinkedinDiv: ShareLinkedinDiv,
    ShareTwitterDiv: ShareTwitterDiv,
    ShareLinksText: ShareLinksText,
    MobileShareLinkDiv: MobileShareLinkDiv,
    ShareDivider: ShareDivider,
  },
};
